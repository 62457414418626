import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-links">
        <ul>
          <li><a href="/realtor">Request Local Realtor</a></li>
          {/* <li><a href="/builder">Request Local Builder</a></li> */}
          <li><a href="/application">Service Providers</a></li>
          <li><a href="/blog">Articles</a></li>
        </ul>
      </div>
      <div className="info-text">
        <p>
          This website has been created in an effort to assist users with resources required
          to relocate to Tennessee. The website sponsor does not guarantee or warrant 
          any vendors that are listed on this site, we have simply provided resources in one place.
          Web links were researched to the best of our ability. If you find incorrect or additional 
          information, we would appreciate your comments/corrections be entered on the Feedback page.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
