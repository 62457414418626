import React from 'react';
import './Article.css';

const RelocateTennessee2024 = () => {
    return (
        <div className="article-page">
            <h1>Top Reasons to Relocate to Tennessee in 2024</h1>
            <p className="article-meta">By Alice Johnson | September 6, 2024</p>
            <img src="./RelocateTennessee2024.jpg" alt="Relocate Tennessee 2024" className="article-image"/>
            <p>Discover why more and more people are choosing Tennessee as their new home in 2024...</p>
            {/* Add more content here */}
        </div>
    );
};

export default RelocateTennessee2024;
