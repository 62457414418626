const EastTNCities = [
  {
    name: "Alcoa (Incorporated)",
    county: "Blount County",
    zip: "37701",
    government: "https://www.cityofalcoa-tn.gov/",
    demographics: "https://data.census.gov/profile/Alcoa_city,_Tennessee?g=160XX00US4700540",
    electric: "https://www.cityofalcoa-tn.gov/",
    waterSewer: "https://cityofalcoa-tn.gov/271/Water-Wastewater-Services",
    sewer: "",
    cableInternet: "Spectrum/ Xfinity/ AT&T",
    gas: "",
    trashRemoval: [ "https://www.cityofalcoa-tn.gov/" ],
    publicSchools: [ "https://www.alcoaschools.net/", "https://www.greatschools.org/tennessee/alcoa/" ]
  },
  {
    name: "Baneberry (Incorporated)",
    county: "Jefferson County",
    zip: "37890",
    government: "https://cityofbaneberry.com/",
    demographics: "https://data.census.gov/profile/Baneberry_city,_Tennessee?g=160XX00US4703078",
    electric: "https://aecoop.org/",
    waterSewer: "https://www.wittutilitydistrict.com/",
    sewer: "https://jeffersoncountytn.gov/environmental-health/",
    cableInternet: "https://www.trilight.net/",
    gas: "https://www.jccud.com/",
    trashRemoval: [ "https://cityofbaneberry.com/services-and-utilities/" ],
    publicSchools: [ "https://www.niche.com/k12/search/best-public-schools/t/baneberry-jefferson-tn/" ]
  },
  {
    name: "Bean Station (Incorporated)",
    county: "Grainger County",
    zip: "37708",
    government: "https://www.beanstation.info/",
    demographics: "https://data.census.gov/profile/Bean_Station_city,_Tennessee?g=160XX00US4703760",
    electric: "https://www.holstonelectric.com/rates",
    waterSewer: "https://beanstationutility.org/",
    sewer: "",
    cableInternet: "Spectrum",
    gas: "",
    trashRemoval: ["https://www.meridianwaste.com/tn/bean-station/", "https://tricitiessanitation.com/" ],
    publicSchools: ["https://beanstation.grainger.k12.tn.us/", "https://www.grainger.k12.tn.us/", "https://www.greatschools.org/tennessee/bean-station/" ]
  },
  {
    name: "Blaine (Incorporated)",
    county: "Grainger County",
    zip: "37709",
    government: "https://www.blainetn.gov/",
    demographics: "https://data.census.gov/profile/Blaine_city,_Tennessee?g=160XX00US4706340",
    electric: "https://www.aecoop.org/",
    waterSewer: "https://www.lbcud.com/",
    sewer: "",
    cableInternet: "Xfinity",
    gas: "",
    trashRemoval: ["https://doanesdisposalservice.com/", "https://www.meridianwaste.com/tn/blaine/" ],
    publicSchools: ["https://www.grainger.k12.tn.us/"]
  },
  {
    name: "Blountville",
    county: "Sullivan County",
    zip: "37617",
    government: "https://blounttn.gov/",
    demographics: "https://data.census.gov/profile/Blountville_CDP,_Tennessee?g=160XX00US4706740",
    electric: "https://www.btes.net/",
    waterSewer: "https://blountvilleud.nexbillpayonline.com/",
    sewer: "",
    cableInternet: "CenturyLink, Spectrum, Xfinity",
    gas: "",
    trashRemoval: [ "https://sullivancountytn.gov/?page_id=225" ],
    publicSchools: ["http://www.sullivank12.net/", "https://www.greatschools.org/tennessee/blountville/sullivan-county-school-district/" ]
  },
  {
    name: "Bluff City (Incorporated)",
    county: "Sullivan County",
    zip: "37618",
    government: "https://www.bluffcitytn.org/",
    demographics: "https://data.census.gov/profile/Bluff_City_city,_Tennessee?g=160XX00US4706960",
    electric: "https://www.btes.net/",
    waterSewer: "https://www.doxo.com/info/bluff-city-waterworks",
    sewer: "",
    cableInternet: "Spectrum",
    gas: "https://www.doxo.com/info/bluff-city-waterworks",
    trashRemoval: [ "https://www.coxdisposal.com/" ],
    publicSchools: ["http://www.sullivank12.net/", "https://www.greatschools.org/tennessee/bluff-city/" ]
  },
  {
    name: "Bristol (Incorporated)",
    county: "Sullivan County",
    zip: "37620",
    government: "https://www.bristoltn.org/",
    demographics: "https://data.census.gov/profile/Bristol_city,_Tennessee?g=160XX00US4708540",
    electric: "https://www.btes.net/",
    waterSewer: "https://www.bristoltn.org/131/Public-Works",
    sewer: "https://www.countyoffice.org/bristol-city-sewer-bristol-tn-1c8/",
    cableInternet: "https://www.btes.net/",
    gas: "https://www.atmosenergy.com/",
    trashRemoval: ["https://www.bristoltn.org/149/City-Finance-Services", "http://www.sullivank12.net/" ],
    publicSchools: ["http://www.sullivank12.net/", "https://www.btcs.org/", "https://www.facebook.com/bristoltncityschools/" ]
  },
  {
    name: "Bulls Gap",
    county: "Hawkins County",
    zip: "37711",
    government: "https://www.bullsgaptn.org/",
    demographics: "https://data.census.gov/profile/Bulls_Gap_town,_Tennessee?g=160XX00US4709560",
    electric: "https://www.countyoffice.org/holston-electric-co-op-inc-church-hill-tn-5df/",
    waterSewer: "https://rwud.com/",
    sewer: "",
    cableInternet: "Xfinity",
    gas: "https://www.countyoffice.org/hawkins-county-gas-utility-rogersville-tn-5a6/",
    trashRemoval: [ "https://tricitiessanitation.com/" ],
    publicSchools: ["https://bgs.hck12.net/", "https://www.greatschools.org/tennessee/bulls-gap/474-Bulls-Gap-School/" ]
  },
  {
    name: "Butler",
    county: "Carter County, Johnson County",
    zip: "37640",
    government: "https://www.cartercountytn.gov/",
    demographics: "https://data.census.gov/profile/Butler_CDP,_Tennessee?g=160XX00US4710080",
    electric: "https://www.mountainelectric.com/",
    waterSewer: "https://www.countyoffice.org/dry-run-utility-district-butler-tn-663/",
    sewer: "",
    cableInternet: "Spectrum",
    gas: "",
    trashRemoval: [ "" ],
    publicSchools: [ "https://www.carterk12.org/" ]
  },
  {
    name: "Caryville (Incorporated)",
    county: "Campbell County",
    zip: "37714",
    government: "No Website",
    demographics: "https://data.census.gov/profile/Caryville_town,_Tennessee?g=160XX00US4711360",
    electric: "https://www.lub.org/rates/electric-rates/",
    waterSewer: "https://waterzen.com/water-providers/caryville-jacksboro-utility/",
    sewer: "",
    cableInternet: "Xfinity, EarthLink, AT&T",
    gas: "",
    trashRemoval: [ "https://campbellcountytn.gov/departments/sanitation/" ],
    publicSchools: ["https://www.campbell.k12.tn.us/", "https://www.publicschoolreview.com/tennessee/caryville" ]
  },
  {
    name: "Church Hill",
    county: "Hawkins County",
    zip: "37642, 37645",
    government: "http://www.churchhilltn.gov/",
    demographics: "https://data.census.gov/profile/Church_Hill_city,_Tennessee?g=160XX00US4714980",
    electric: "https://www.countyoffice.org/holston-electric-co-op-inc-church-hill-tn-5df/",
    waterSewer: "https://www.countyoffice.org/first-utility-district-hawkins-church-hill-tn-461/",
    sewer: "",
    cableInternet: "Spectrum, BrightSpeed, Century Link",
    gas: "https://www.countyoffice.org/hawkins-county-gas-utility-rogersville-tn-5a6/",
    trashRemoval: [ "https://tricitiessanitation.com/" ],
    publicSchools: ["https://www.greatschools.org/tennessee/church-hill/hawkins-county-school-district/", "https://www.greatschools.org/tennessee/church-hill/hawkins-county-school-district/" ]
  },
  {
    name: "Clinton (Incorporated)",
    county: "Anderson County",
    zip: "37716",
    government: "http://clintontn.net/",
    demographics: "https://data.census.gov/profile/Clinton_city,_Tennessee?g=160XX00US4715580",
    electric: "https://www.clintonub.com/",
    waterSewer: "https://www.clintonub.com/",
    sewer: "https://www.clintonub.com/",
    cableInternet: "Xfinity, AT&T",
    gas: "",
    trashRemoval: [ "" ],
    publicSchools: ["https://www.clintonschools.org/", "https://www.publicschoolreview.com/tennessee/clinton-school-district/4700720-school-district" ]
  },
  {
    name: "Coker Creek",
    county: "Monroe County",
    zip: "37314",
    government: "No Website",
    demographics: "https://data.census.gov/profile/Coker_Creek_CDP,_Tennessee?g=160XX00US4716060",
    electric: "https://www.flec.org/",
    waterSewer: "https://www.tellicoplainstn.com/water",
    sewer: "",
    cableInternet: "AT&T, Spectrum, Xfinity",
    gas: "",
    trashRemoval: [ "" ],
    publicSchools: ["https://www.monroe.k12.tn.us/apps/pages/index.jsp?uREC_ID=1147526&type=d&pREC_ID=1408830", "https://www.greatschools.org/tennessee/tellico-plains/982-Coker-Creek-Elementary-School/" ]
  },
  {
    name: "Colonial Heights",
    county: "Sullivan County",
    zip: "37663",
    government: "No Website",
    demographics: "https://data.census.gov/profile/Colonial_Heights_CDP,_Tennessee?g=160XX00US4716500",
    electric: "https://www.aep.com/",
    waterSewer: "https://www.kingsporttn.gov/city-services/",
    sewer: "",
    cableInternet: "Spectrum",
    gas: "",
    trashRemoval: [ "https://www.kingsporttn.gov/city-services/" ],
    publicSchools: [ "http://www.sullivank12.net/" ]
  },
  {
    name: "Cosby",
    county: "Cocke County",
    zip: "37722",
    government: "https://www.cockecountytn.gov/",
    demographics: "https://data.census.gov/profile/Cosby_CDP,_Tennessee?g=160XX00US4717300",
    electric: "https://newportutilities.com/",
    waterSewer: "https://newportutilities.com/",
    sewer: "",
    cableInternet: "Xfinity, Newport Utilities, Starlink",
    gas: "https://www.jccud.com/",
    trashRemoval: [ "" ],
    publicSchools: [ "https://cockecountyschools.org/" ]
  },
  {
    name: "Cumberland Gap (Incorporated)",
    county: "Claiborne County",
    zip: "37724",
    government: "https://www.townofcumberlandgap.com/",
    demographics: "https://data.census.gov/profile/Cumberland_Gap_town,_Tennessee?g=160XX00US4718880",
    electric: "https://www.lub.org/rates/electric-rates/",
    waterSewer: "https://www.townofcumberlandgap.com/pay-your-utility-bill",
    sewer: "",
    cableInternet: "Satellite/Wireless Only",
    gas: "",
    trashRemoval: [ "" ],
    publicSchools: ["http://www.claibornecountyschools.com/", "https://www.greatschools.org/tennessee/cumberland-gap/2334-Cumberland-Gap-High-School/" ]
  },
  {
    name: "Dandridge",
    county: "Jefferson County",
    zip: "37725",
    government: "https://dandridgetn.gov/",
    demographics: "https://data.census.gov/profile/Dandridge_town,_Tennessee?g=160XX00US4719380",
    electric: "https://www.aecoop.org/",
    waterSewer: "https://dandridgewater.com/",
    sewer: "",
    cableInternet: "AT&T, Spectrum, Xfinity",
    gas: "https://www.jccud.com/",
    trashRemoval: [ "" ],
    publicSchools: ["https://www.jc-tn.net/schools", "https://www.greatschools.org/tennessee/dandridge/jefferson-county-school-district/" ]
  },
  {
    name: "Del Rio",
    county: "Cocke County",
    zip: "37727",
    government: "https://www.facebook.com/CityofDelRio/",
    demographics: "https://data.census.gov/profile/Del_Rio_CCD,_Cocke_County,_Tennessee?g=060XX00US4702990960",
    electric: "https://newportutilities.com/",
    waterSewer: "https://newportutilities.com/",
    sewer: "",
    cableInternet: "https://broadbandnow.com/Newport-Utilities",
    gas: "",
    trashRemoval: [ "" ],
    publicSchools: ["https://cockecountyschools.org/", "https://www.greatschools.org/tennessee/del-rio/207-Del-Rio-Elementary-School/" ]
  },
  {
    name: "Elizabethton (Incorporated)",
    county: "Carter County",
    zip: "37643",
    government: "https://www.elizabethton.org/",
    demographics: "https://data.census.gov/profile/Elizabethton_city,_Tennessee?g=160XX00US4723500",
    electric: "https://www.eesonline.org/",
    waterSewer: "https://www.municipalonlinepayments.com/elizabethtontn/utilities",
    sewer: "",
    cableInternet: "Spectrum, Xfinity, Brightspeed",
    gas: "",
    trashRemoval: [ "" ],
    publicSchools: ["https://www.ecschools.net/", "https://www.greatschools.org/tennessee/elizabethton/", "https://www.carterk12.org/" ]
  },
  {
    name: "Friendsville (Incorporated)",
    county: "Blount County",
    zip: "37737",
    government: "https://www.friendsvilletn.gov/",
    demographics: "https://data.census.gov/profile/Friendsville_city,_Tennessee?g=160XX00US4728060",
    electric: "https://www.flec.org/",
    waterSewer: "https://www.friendsvilletn.gov/utility-department/new-customers",
    sewer: "",
    cableInternet: "AT&T, Xfinity, Spectrum",
    gas: "",
    trashRemoval: [ "" ],
    publicSchools: ["https://www.blountk12.org/", "https://www.greatschools.org/tennessee/friendsville/" ]
  },
  {
    name: "Gatlinburg (Incorporated)",
    county: "Sevier County",
    zip: "37738",
    government: "https://www.gatlinburgtn.gov/",
    demographics: "https://data.census.gov/profile/Gatlinburg_city,_Tennessee?g=160XX00US4728800",
    electric: "https://sces.net/billing/",
    waterSewer: "https://www.gatlinburgtn.gov/page/utilities",
    sewer: "",
    cableInternet: "Spectrum, Xfinity, AT&T",
    gas: "",
    trashRemoval: [ "" ],
    publicSchools: ["https://www.sevier.org/", "https://www.greatschools.org/tennessee/gatlinburg/" ]
  },
  {
    name: "Gray",
    county: "Washington County",
    zip: "37615",
    government: "https://www.washingtoncountytn.org/",
    demographics: "https://data.census.gov/profile/Gray_CDP,_Tennessee?g=160XX00US4730700",
    electric: "https://www.brightridge.com/",
    waterSewer: "https://www.johnsoncitytn.org/water_sewer_services.php",
    sewer: "",
    cableInternet: "https://broadbandnow.com/BrightRidge",
    gas: "",
    trashRemoval: [ "" ],
    publicSchools: ["https://www.wcde.org/", "https://www.greatschools.org/tennessee/gray/" ]
  },
  {
    name: "Greenback (Incorporated)",
    county: "Loudon County",
    zip: "37742",
    government: "https://www.greenbackgov.com/",
    demographics: "https://data.census.gov/profile/Greenback_city,_Tennessee?g=160XX00US4730880",
    electric: "https://www.greenbackgov.com/electric-gas-and-water-utilities",
    waterSewer: "https://www.greenbackgov.com/electric-gas-and-water-utilities",
    sewer: "",
    cableInternet: "EarthLink, AT&T, Xfinity",
    gas: "https://www.greenbackgov.com/electric-gas-and-water-utilities",
    trashRemoval: [ "" ],
    publicSchools: ["https://gbs.loudoncounty.org/", "https://www.greatschools.org/tennessee/greenback/709-Greenback-School/" ]
  },
  {
    name: "Greeneville (Incorporated)",
    county: "Greene County",
    zip: "37743, 37745",
    government: "https://www.greenevilletn.gov/",
    demographics: "https://data.census.gov/profile/Greeneville_town,_Tennessee?g=160XX00US4730980",
    electric: "https://www.brightridge.com/",
    waterSewer: "https://rwud.com/",
    sewer: "",
    cableInternet: "Xfinity, Brightspeed",
    gas: "",
    trashRemoval: [ "https://tricitiessanitation.com/" ],
    publicSchools: ["https://www4.gcschools.net/", "https://www.greatschools.org/tennessee/greeneville/" ]
  },
  {
    name: "Halls",
    county: "Knox County",
    zip: "38040",
    government: "https://townofhallstn.us/",
    demographics: "https://data.census.gov/profile/Halls_town,_Tennessee?g=160XX00US4731820",
    electric: "https://www.countyoffice.org/ripley-power-and-light-company-halls-tn-7f4/",
    waterSewer: "https://www.countyoffice.org/first-utility-district-of-knox-county-knoxville-tn-96a/",
    sewer: "",
    cableInternet: "Spectrum, EarthLink, AT&T",
    gas: "",
    trashRemoval: [ "" ],
    publicSchools: ["https://www.knoxschools.org/", "https://www.greatschools.org/tennessee/knoxville/knox-county-school-district/" ]
  },
  {
    name: "Hampton",
    county: "Carter County",
    zip: "37658",
    government: "https://www.cartercountytn.gov/",
    demographics: "https://data.census.gov/profile/Hampton_CDP,_Tennessee?g=160XX00US4732040",
    electric: "https://www.brightridge.com/",
    waterSewer: "https://www.facebook.com/people/Hampton-TN-Water-Department/100066744585075/",
    sewer: "",
    cableInternet: "Xfinity, BrightSpeed",
    gas: "",
    trashRemoval: [ "" ],
    publicSchools: ["https://www.carterk12.org/", "https://www.greatschools.org/tennessee/hampton/", "https://www.facebook.com/hamptonhightn/" ]
  },
  {
    name: "Harrogate (Incorporated)",
    county: "Claiborne County",
    zip: "37752",
    government: "http://harrogate-tn.com/",
    demographics: "https://data.census.gov/profile/Harrogate_city,_Tennessee?g=160XX00US4732640",
    electric: "https://www.lub.org/rates/electric-rates/",
    waterSewer: "https://www.asudtn.com/",
    sewer: "",
    cableInternet: "EarthLink, AT&T, Point Broadband",
    gas: "",
    trashRemoval: [ "" ],
    publicSchools: ["http://www.claibornecountyschools.com/", "https://www.greatschools.org/tennessee/harrogate/" ]
  },


   {
    name: "Jacksboro (Incorporated)",
    county: "Campbell County",
    zip: "37757",
    government: "https://campbellcountytn.gov/",
    demographics: "https://data.census.gov/profile/Jacksboro_town,_Tennessee?g=160XX00US4737600",
    electric: "https://www.lub.org/rates/electric-rates/",
    waterSewer: "https://waterzen.com/water-providers/caryville-jacksboro-utility/",
    sewer: "",
    cableInternet: "AT&T, Xfinity",
    gas: "",
    trashRemoval: [ "" ],
    publicSchools: ["https://www.campbell.k12.tn.us/", "https://www.greatschools.org/tennessee/jacksboro/" ]
  },
  {
    name: "Jefferson City (Incorporated)",
    county: "Jefferson County",
    zip: "37760",
    government: "https://jeffcitytn.com/",
    demographics: "https://data.census.gov/profile/Jefferson_City_city,_Tennessee?g=160XX00US4737960",
    electric: "https://www.aecoop.org/",
    waterSewer: "https://jeffcitytn.com/city_services/water_sewer/index.php",
    sewer: "",
    cableInternet: "AT&T, Spectrum, Trilight",
    gas: "https://www.jccud.com/",
    trashRemoval: [ "https://jeffcitytn.com/city_services/trash_recycling_/index.php" ],
    publicSchools: ["https://www.jc-tn.net/schools", "https://www.greatschools.org/tennessee/jefferson-city/" ]
  },
  {
    name: "Jellico (Incorporated)",
    county: "Campbell County",
    zip: "37762",
    government: "https://www.jellicocity.com/",
    demographics: "https://data.census.gov/profile/Jellico_city,_Tennessee?g=160XX00US4738020",
    electric: "https://www.jellicoutilities.com/",
    waterSewer: "https://www.jellicoutilities.com/",
    sewer: "",
    cableInternet: "EarthLink, AT&T",
    gas: "",
    trashRemoval: [ "https://www.jellicocity.com/sanitation-department" ],
    publicSchools: ["https://www.campbell.k12.tn.us/", "https://www.greatschools.org/tennessee/jellico/" ]
  },
  {
    name: "Johnson City (Incorporated)",
    county: "Carter County, Sullivan County, Washington County",
    zip: "37601, 37604, 37615",
    government: "https://www.johnsoncitytn.org/",
    demographics: "https://data.census.gov/profile/Johnson_City_city,_Tennessee?g=160XX00US4738320",
    electric: "https://www.brightridge.com/",
    waterSewer: "https://www.johnsoncitytn.org/water_sewer_services.php",
    sewer: "",
    cableInternet: "EarthLink, Spectrum, BrightSpeed, CenturyLink",
    gas: "",
    trashRemoval: [ "https://www.johnsoncitytn.org/services/trash_and_recycle_pickup.php" ],
    publicSchools: ["https://www.jcschools.org/schools/", "https://www.carterk12.org/", "https://www.wcde.org/", "https://www.facebook.com/JCityTNSchools/", "https://www.greatschools.org/tennessee/johnson-city/" ]
  },
  {
    name: "Jonesborough (Incorporated)",
    county: "Washington County",
    zip: "37659",
    government: "https://www.jonesboroughtn.org/",
    demographics: "https://data.census.gov/profile/Jonesborough_town,_Tennessee?g=160XX00US4738540",
    electric: "https://www.brightridge.com/",
    waterSewer: "https://www.jonesboroughtn.org/town-services/water-and-wastewater/water-administration/",
    sewer: "",
    cableInternet: "Xfinity, BrightSpeed",
    gas: "",
    trashRemoval: [ "https://www.jonesboroughtn.org/town-services/solid-waste-recycling/" ],
    publicSchools: ["https://www.wcde.org/", "https://www.greatschools.org/tennessee/jonesborough/" ]
  },
  {
    name: "Kingsport (Incorporated)",
    county: "Hawkins County, Sullivan County",
    zip: "37660, 37663, 37664, 37665",
    government: "https://www.kingsporttn.gov/",
    demographics: "https://data.census.gov/profile/Kingsport_city,_Tennessee?g=160XX00US4739560",
    electric: "https://www.countyoffice.org/holston-electric-co-op-inc-church-hill-tn-5df/",
    waterSewer: "https://www.kingsporttn.gov/city-services/",
    sewer: "",
    cableInternet: "Spectrum, BrightSpeed",
    gas: "https://www.countyoffice.org/east-tennessee-natural-gas-co-kingsport-tn-6da/",
    trashRemoval: [ "https://tricitiessanitation.com/" ],
    publicSchools: ["https://www.k12k.com/", "http://www.sullivank12.net/", "https://www.greatschools.org/tennessee/kingsport/", "https://www.hck12.net/" ]
  },
  {
    name: "Knoxville (Incorporated)",
    county: "Knox County",
    zip: "37902, 37909, 37912, 37914, 37915, 37916, 37917, 37918, 37919, 37920, 37921, 37923, 37924, 37929, 37931, 37932, 37934, 37938",
    government: "https://www.knoxvilletn.gov/",
    demographics: "https://data.census.gov/profile/Knoxville_city,_Tennessee?g=160XX00US4740000",
    electric: "https://www.knoxvilletn.gov/",
    waterSewer: "https://www.knoxvilletn.gov/",
    sewer: "",
    cableInternet: "Xfinity, AT&T, Spectrum, WOW",
    gas: "https://www.knoxvilletn.gov/",
    trashRemoval: [ "https://www.knoxvilletn.gov/government/city_departments_offices/public_service/waste_and_resources_management/garbage" ],
    publicSchools: ["https://www.knoxschools.org/", "https://www.facebook.com/knoxschools/", "https://www.greatschools.org/tennessee/knoxville/knox-county-school-district/" ]
  },
  {
    name: "La Follette (Incorporated)",
    county: "Campbell County",
    zip: "37766",
    government: "https://www.lafollettetn.gov/",
    demographics: "https://data.census.gov/profile/La_Follette_city,_Tennessee?g=160XX00US4740180",
    electric: "https://www.lub.org/",
    waterSewer: "https://www.lub.org/",
    sewer: "",
    cableInternet: "AT&T, Xfinity",
    gas: "",
    trashRemoval: [ "https://www.lafollettetn.gov/departments/public-works-department" ],
    publicSchools: ["https://www.campbell.k12.tn.us/", "https://www.greatschools.org/tennessee/lafollette/" ]
  },
  {
    name: "Lenoir City (Incorporated)",
    county: "Loudon County",
    zip: "37771, 37772",
    government: "https://www.lenoircitytn.gov/",
    demographics: "https://data.census.gov/profile/Lenoir_City_city,_Tennessee?g=160XX00US4741760",
    electric: "https://www.lcub.com/water/",
    waterSewer: "https://www.lcub.com/water/",
    sewer: "",
    cableInternet: "https://www.lcub.com/water/",
    gas: "",
    trashRemoval: [ "https://www.lenoircitytn.gov/street-sanitation-department/" ],
    publicSchools: ["https://www.lenoircityschools.com/", "https://www.greatschools.org/tennessee/lenoir-city/lenoir-city-school-district/" ]
  },

   {
    name: "Loudon (Incorporated)",
    county: "Loudon County",
    zip: "37774",
    government: "https://www.cityofloudontn.org/",
    demographics: "https://data.census.gov/profile/Loudon_city,_Tennessee?g=160XX00US4743780",
    electric: "https://www.loudonutilities.org/",
    waterSewer: "https://www.loudonutilities.org/",
    sewer: "",
    cableInternet: "AT&T, Spectrum",
    gas: "",
    trashRemoval: [ "https://www.cityofloudontn.org/departments/facilities_maintenance/sanitation.php" ],
    publicSchools: ["https://gbs.loudoncounty.org/", "https://www.greatschools.org/tennessee/loudon/loudon-county-school-district/" ]
  },
  {
    name: "Louisville (Incorporated)",
    county: "Blount County",
    zip: "37777",
    government: "https://www.louisvilletn.gov/",
    demographics: "https://data.census.gov/profile/Louisville_city,_Tennessee?g=160XX00US4743820",
    electric: "https://www.louisvilletn.gov/page/electric-and-water",
    waterSewer: "https://www.louisvilletn.gov/page/electric-and-water",
    sewer: "",
    cableInternet: "AT&T, Spectrum",
    gas: "",
    trashRemoval: [ "https://www.meridianwaste.com/tn/louisville/" ],
    publicSchools: ["https://www.blountk12.org/", "https://www.greatschools.org/tennessee/louisville/" ]
  },
  {
    name: "Luttrell (Incorporated)",
    county: "Union County",
    zip: "37779",
    government: "https://www.facebook.com/people/City-of-Luttrell/100064738024527/",
    demographics: "https://data.census.gov/profile/Luttrell_town,_Tennessee?g=160XX00US4744300",
    electric: "https://www.lub.org/rates/electric-rates/",
    waterSewer: "https://www.lbcud.com/",
    sewer: "",
    cableInternet: "Xfinity, AT&T",
    gas: "",
    trashRemoval: [ "https://www.unioncountytn.gov/solid-waste-and-recycling/" ],
    publicSchools: ["https://www.ucps.org/schools", "https://www.greatschools.org/tennessee/luttrell/1488-Luttrell-Elementary-School/" ]
  },
  {
    name: "Madisonville (Incorporated)",
    county: "Monroe County",
    zip: "37354",
    government: "https://www.madisonvilletn.net/",
    demographics: "https://data.census.gov/profile/Madisonville_city,_Tennessee?g=160XX00US4745320",
    electric: "https://www.flec.org/",
    waterSewer: "https://www.madisonvilletn.net/departments/water-gas-department/",
    sewer: "",
    cableInternet: "AT&T, Spectrum",
    gas: "",
    trashRemoval: [ "https://monroetn.gov/solid-waste/ https://dynamicwasteco.com/" ],
    publicSchools: ["https://www.monroe.k12.tn.us/", "https://www.greatschools.org/tennessee/madisonville/" ]
  },
  {
    name: "Maryville",
    county: "Blount County",
    zip: "37801, 37803, 37804",
    government: "https://www.maryvillegov.com/",
    demographics: "https://data.census.gov/profile/Maryville_city,_Tennessee?g=160XX00US4746380",
    electric: "https://www.maryvillegov.com/customer-service.html",
    waterSewer: "https://www.southblountutility.org/about/about-blount-utilitiy",
    sewer: "",
    cableInternet: "AT&T, Spectrum",
    gas: "https://www.countyoffice.org/east-tennessee-natural-gas-co-maryville-tn-6db/",
    trashRemoval: [ "https://www.maryvillegov.com/sanitation.html" ],
    publicSchools: ["https://www.maryville-schools.org/", "https://www.blountk12.org/", "https://www.greatschools.org/tennessee/maryville/" ]
  },
  {
    name: "Mascot",
    county: "Knox County",
    zip: "37806",
    government: "No Website",
    demographics: "https://data.census.gov/profile/Mascot_CDP,_Tennessee?g=160XX00US4746400",
    electric: "https://www.kub.org/",
    waterSewer: "https://www.countyoffice.org/first-utility-district-of-knox-county-knoxville-tn-96a/",
    sewer: "",
    cableInternet: "Xfinity, AT&T",
    gas: "",
    trashRemoval: [ "Unknown" ],
    publicSchools: ["https://www.knoxschools.org/", "https://www.greatschools.org/tennessee/knoxville/knox-county-school-district/" ]
  },
  {
    name: "Maynardville (Incorporated)",
    county: "Union County",
    zip: "37807",
    government: "http://maynardvilletn.com/",
    demographics: "https://data.census.gov/profile/Maynardville_city,_Tennessee?g=160XX00US4746700",
    electric: "https://www.lub.org/rates/electric-rates/",
    waterSewer: "http://maynardvilletn.com/index.html",
    sewer: "",
    cableInternet: "Xfinity, AT&T",
    gas: "",
    trashRemoval: [ "https://www.meridianwaste.com/tn/maynardville/" ],
    publicSchools: ["https://www.ucps.org/schools", "https://www.facebook.com/UnionCountyPublicSchools/", "https://www.greatschools.org/tennessee/maynardville/" ]
  },
  {
    name: "Mooresburg",
    county: "Hawkins County",
    zip: "37811",
    government: "No Website",
    demographics: "https://data.census.gov/profile/Mooresburg_CDP,_Tennessee?g=160XX00US4749940",
    electric: "https://www.countyoffice.org/holston-electric-co-op-inc-church-hill-tn-5df/",
    waterSewer: "https://www.countyoffice.org/mooresburg-utilities-district-mooresburg-tn-1e5/",
    sewer: "",
    cableInternet: "https://broadbandnow.com/HolstonConnect",
    gas: "https://www.countyoffice.org/hawkins-county-gas-utility-rogersville-tn-5a6/",
    trashRemoval: [ "https://tricitiessanitation.com/" ],
    publicSchools: ["https://www.hck12.net/", "https://www.greatschools.org/tennessee/mooresburg/482-Mooresburg-Elementary-School/" ]
  },
  {
    name: "Morristown (Incorporated)",
    county: "Jefferson County, Hamblen County",
    zip: "37813, 37814",
    government: "https://mymorristown.com/",
    demographics: "https://data.census.gov/profile/Morristown_city,_Tennessee?g=160XX00US4750280",
    electric: "https://www.aecoop.org/",
    waterSewer: "https://rwud.com/",
    sewer: "",
    cableInternet: "AT&T, Spectrum, Xfinity",
    gas: "https://www.jccud.com/",
    trashRemoval: [ "https://www.mymorristown.com/departments/public_works/recycling.php" ],
    publicSchools: ["https://www.jc-tn.net/schools", "https://hamblencountydoe.schoolinsites.com/", "https://www.publicschoolreview.com/tennessee/morristown/37813" ]
  },
  {
    name: "Mosheim (Incorporated)",
    county: "Greene County",
    zip: "37818",
    government: "https://www.mosheim.net/town-hall",
    demographics: "https://data.census.gov/profile/Mosheim_town,_Tennessee?g=160XX00US4750320",
    electric: "https://www.brightridge.com/",
    waterSewer: "https://rwud.com/",
    sewer: "",
    cableInternet: "Xfinity, BrightSpeed",
    gas: "",
    trashRemoval: [ "https://tricitiessanitation.com/" ],
    publicSchools: ["https://mosheim.greenek12.org/", "https://www.facebook.com/gcsstn/", "https://www.greatschools.org/tennessee/mosheim/" ]
  },
  {
    name: "Mount Carmel (Incorporated)",
    county: "Hawkins County",
    zip: "37645",
    government: "https://mountcarmeltn.gov/",
    demographics: "https://data.census.gov/profile/Mount_Carmel_town,_Tennessee?g=160XX00US4750580",
    electric: "https://www.countyoffice.org/holston-electric-co-op-inc-church-hill-tn-5df/",
    waterSewer: "https://rwud.com/",
    sewer: "",
    cableInternet: "Spectrum, BrightSpeed",
    gas: "https://www.countyoffice.org/hawkins-county-gas-utility-rogersville-tn-5a6/",
    trashRemoval: [ "https://tricitiessanitation.com/" ],
    publicSchools: ["https://www.hck12.net/", "https://www.greatschools.org/tennessee/mount-carmel/483-Mt-Carmel-Elementary-School/" ]
  },
  {
    name: "Mountain City (Incorporated)",
    county: "Johnson County",
    zip: "37683",
    government: "https://www.mountaincitytn.org/",
    demographics: "https://data.census.gov/profile/Mountain_City_town,_Tennessee?g=160XX00US4750400",
    electric: "https://www.mountainelectric.com/",
    waterSewer: "https://www.mountaincitytn.org/departments/water/index.html",
    sewer: "",
    cableInternet: "Spectrum, BrightSpeed",
    gas: "",
    trashRemoval: [ "https://www.mountaincitytn.org/departments/public_works/index.html", "https://www.republicservices.com/locations/tennessee/mountain-city-trash-pickup-and-recycling" ],
    publicSchools: ["https://www.jocoed.net/", "https://www.greatschools.org/tennessee/mountain-city/johnson-county-school-district/" ]
  },
  {
    name: "New Market (Incorporated)",
    county: "Jefferson County",
    zip: "37820",
    government: "https://www.facebook.com/newmarketcityhall/",
    demographics: "https://data.census.gov/profile/New_Market_town,_Tennessee?g=160XX00US4752940",
    electric: "https://www.aecoop.org/",
    waterSewer: "https://newmarketutility.com/",
    sewer: "",
    cableInternet: "EarthLink, AT&T, Spectrum",
    gas: "https://www.jccud.com/",
    trashRemoval: [ "https://www.meridianwaste.com/tn/new-market/" ],
    publicSchools: ["https://www.jc-tn.net/schools", "https://www.greatschools.org/tennessee/new-market/" ]
  },
  {
    name: "New Tazewell (Incorporated)",
    county: "Claiborne County",
    zip: "37825",
    government: "https://newtazewelltn.org/",
    demographics: "https://data.census.gov/profile/New_Tazewell_town,_Tennessee?g=160XX00US4753140",
    electric: ["https://www.lub.org/rates/electric-rates/", "https://www.pve.coop/"],
    waterSewer: "https://www.asudtn.com/",
    sewer: "",
    cableInternet: "https://broadbandnow.com/mountainet",
    gas: "",
    trashRemoval: [ "https://claibornecountytn.gov/countyoffices/county-services/sanitation/" ],
    publicSchools: ["http://www.claibornecountyschools.com/", "https://www.greatschools.org/tennessee/new-tazewell/" ]
  },
  {
    name: "Newport (Incorporated)",
    county: "Cocke County",
    zip: "37821",
    government: "https://www.cityofnewport-tn.com/",
    demographics: "https://data.census.gov/profile/Newport_city,_Tennessee?g=160XX00US4753000",
    electric: "https://newportutilities.com/",
    waterSewer: "https://newportutilities.com/",
    sewer: "",
    cableInternet: "https://broadbandnow.com/Newport-Utilities",
    gas: "https://www.jccud.com/",
    trashRemoval: [ "https://www.cityofnewport-tn.com/?SEC=2ABB52FD-9010-424F-ABBA-94E56967656F" ],
    publicSchools: ["https://www.newportcityschools.org/", "https://cockecountyschools.org/", "https://www.greatschools.org/tennessee/newport/" ]
  },
  {
    name: "Norris (Incorporated)",
    county: "Anderson County",
    zip: "37828",
    government: "https://www.cityofnorris.com/",
    demographics: "https://data.census.gov/profile/Norris_city,_Tennessee?g=160XX00US4753600",
    electric: "https://www.clintonub.com/",
    waterSewer: "https://www.cityofnorris.com/",
    sewer: "",
    cableInternet: "Xfinity, EarthLink, AT&T",
    gas: "",
    trashRemoval: [ "https://www.cityofnorris.com/" ],
    publicSchools: ["https://www.acs.ac/?fbclid=IwZXh0bgNhZW0CMTAAAR0wuVrV1JFbjUqVLVJT0wwd_PjLIQKtHZIk59_oGFX1JbHgaJEcpr73y4I_aem_ZmFrZWR1bW15MTZieXRlcw", "https://www.greatschools.org/tennessee/norris/" ]
  },
  {
    name: "Oakdale (Incorporated)",
    county: "Morgan County",
    zip: "37829",
    government: "https://www.morgancountytn.gov/county-executive/",
    demographics: "https://data.census.gov/profile/Oakdale_town,_Tennessee?g=160XX00US4754280",
    electric: "https://oakdale.municipalimpact.com/",
    waterSewer: "https://oakdale.municipalimpact.com/",
    sewer: "",
    cableInternet: "https://broadbandnow.com/Highland-HTC",
    gas: "",
    trashRemoval: [ "https://oakdale.municipalimpact.com/", "https://www.morgancountytn.gov/solid-waste-recycling/" ],
    publicSchools: ["https://oakdale.mcsed.net/", "https://www.greatschools.org/tennessee/oakdale/1026-Oakdale-School/" ]
  },
  {
    name: "Oak Ridge (Incorporated)",
    county: "Anderson County",
    zip: "37830",
    government: "https://www.oakridgetn.gov/",
    demographics: "https://data.census.gov/profile/Oak_Ridge_city,_Tennessee?g=160XX00US4755120",
    electric: "https://www.oakridgetn.gov/194/Oak-Ridge-Electric",
    waterSewer: "https://www.oakridgetn.gov/207/Public-Works",
    sewer: "",
    cableInternet: "AT&T, Xfinity",
    gas: "https://orud.org/customer-service/billing/",
    trashRemoval: [ "https://www.oakridgetn.gov/207/Public-Works" ],
    publicSchools: ["https://www.ortn.edu/schools/", "https://www.greatschools.org/tennessee/oak-ridge/", "https://acs.ac/" ]
  },
  {
    name: "Oliver Springs (Incorporated)",
    county: "Anderson County, Morgan County",
    zip: "37840",
    government: "https://oliversprings-tn.gov/",
    demographics: "https://data.census.gov/profile/Oliver_Springs_town,_Tennessee?g=160XX00US4755800",
    electric: "https://www.clintonub.com/",
    waterSewer: "https://oliversprings-tn.gov/952/Water-Department",
    sewer: "",
    cableInternet: "Xfinity, Earthlink, AT&T",
    gas: "",
    trashRemoval: [ "https://oliversprings-tn.gov/901/Garbage-Services" ],
    publicSchools: ["https://acs.ac/", "https://www.mcsed.net/", "https://www.greatschools.org/tennessee/oliver-springs/" ]
  },
  {
    name: "Parrottsville (Incorporated)",
    county: "Cocke County",
    zip: "37843",
    government: "https://www.facebook.com/people/Town-of-Parrottsville/100088488585363/",
    demographics: "https://data.census.gov/profile/Parrottsville_town,_Tennessee?g=160XX00US4757040",
    electric: "https://newportutilities.com/",
    waterSewer: "https://newportutilities.com/",
    sewer: "",
    cableInternet: "EarthLink, AT&T",
    gas: "https://www.jccud.com/",
    trashRemoval: [ "https://www.republicservices.com/locations/tennessee/parrottsville-trash-pickup-and-recycling" ],
    publicSchools: ["https://cockecountyschools.org/", "https://www.greatschools.org/tennessee/parrottsville/211-Parrottsville-Elementary-School/" ]
  },
  {
    name: "Philadelphia (Incorporated)",
    county: "Loudon County",
    zip: "37846",
    government: "https://philadelphiatn.com/about-us.html",
    demographics: "https://data.census.gov/profile/Philadelphia_city,_Pennsylvania?g=160XX00US4260000",
    electric: "https://www.loudonutilities.org/about_lub/index.php",
    waterSewer: "https://www.loudonutilities.org/about_lub/index.php",
    sewer: "",
    cableInternet: "Spectrum, EarthLink, AT&T",
    gas: "",
    trashRemoval: [ "No Information" ],
    publicSchools: ["https://gbs.loudoncounty.org/", "https://www.greatschools.org/tennessee/philadelphia/" ]
  },
  {
    name: "Pigeon Forge (Incorporated)",
    county: "Sevier County",
    zip: "37862, 37863, 37876",
    government: "https://cityofpigeonforge.com/index.aspx",
    demographics: "https://data.census.gov/profile/Pigeon_Forge_city,_Tennessee?g=160XX00US4758080",
    electric: "https://sces.net/billing/",
    waterSewer: "https://cityofpigeonforge.com/utilities.aspx",
    sewer: "",
    cableInternet: "Spectrum, Xfinity, EarthLink, AT&T",
    gas: "",
    trashRemoval: [ "https://cityofpigeonforge.com/sanitation.aspx" ],
    publicSchools: ["https://www.sevier.org/", "https://www.greatschools.org/tennessee/pigeon-forge/" ]
  },
  {
    name: "Pittman Center",
    county: "Sevier County",
    zip: "37876",
    government: "https://pittmancentertn.gov/",
    demographics: "https://data.census.gov/profile/Pittman_Center_town,_Tennessee?g=160XX00US4758940",
    electric: "https://sces.net/billing/",
    waterSewer: "https://www.wcud.org/about/",
    sewer: "",
    cableInternet: "Xfinity, AT&T",
    gas: "",
    trashRemoval: [ "https://www.seviercountytn.gov/government/departments/services/solid_waste.php" ],
    publicSchools: ["https://www.pc.sevier.org/", "https://www.sevier.org/", "https://www.publicschoolreview.com/pittman-center-elementary-school-profile" ]
  },
  {
    name: "Powell",
    county: "Knox County",
    zip: "37849",
    government: "https://cityofpowell.com/your-government/",
    demographics: "https://data.census.gov/profile/Powell_CDP,_Tennessee?g=160XX00US4760480",
    electric: "No Information",
    waterSewer: "https://www.hpud.org/",
    sewer: "",
    cableInternet: "Xfinity, EarthLink Frontier, WOW",
    gas: "",
    trashRemoval: [ "https://www.knoxcounty.org/solid_waste/map_of_centers.php" ],
    publicSchools: ["https://www.knoxschools.org/", "https://www.facebook.com/knoxschools/", "https://www.greatschools.org/tennessee/powell/" ]
  },
  {
    name: "Rockford (Incorporated)",
    county: "Blount County",
    zip: "37853",
    government: "No Website",
    demographics: "https://data.census.gov/profile/Rockford_city,_Tennessee?g=160XX00US4764160",
    electric: "https://www.maryvillegov.com/electric-department-home-page.html",
    waterSewer: "https://cityofalcoa-tn.gov/271/Water-Wastewater-Services",
    sewer: "",
    cableInternet: "AT&T, Spectrum, Xfinity",
    gas: "",
    trashRemoval: [ "https://www.meridianwaste.com/tn/rockford/" ],
    publicSchools: ["https://www.blountk12.org/", "https://www.greatschools.org/tennessee/rockford/80-Rockford-Elementary-School/" ]
  },
  {
    name: "Rocky Top (Incorporated)",
    county: "Anderson County, Campbell County",
    zip: "37769",
    government: "https://rockytoptn.org/",
    demographics: "https://data.census.gov/profile/Rocky_Top_city,_Tennessee?g=160XX00US4764668",
    electric: "https://www.lub.org/rates/electric-rates/",
    waterSewer: "https://rockytoptn.org/make-a-payment/pay-water-bill/",
    sewer: "",
    cableInternet: "Satellite/Wireless Only",
    gas: "",
    trashRemoval: [ "https://andersoncountytn.gov/solid-waste-recycling/" ],
    publicSchools: ["https://www.campbell.k12.tn.us/", "https://acs.ac/", "https://www.greatschools.org/tennessee/rocky-top/35-Lake-City-Middle-School/" ]
  },
  {
    name: "Rogersville (Incorporated)",
    county: "Hawkins County",
    zip: "37857",
    government: "https://townofrogersville.com/",
    demographics: "https://data.census.gov/profile/Rogersville_town,_Tennessee?g=160XX00US4764820",
    electric: "https://www.countyoffice.org/holston-electric-co-op-inc-church-hill-tn-5df/",
    waterSewer: "https://www.countyoffice.org/lakeview-utility-district-rogersville-tn-ebf/",
    sewer: "",
    cableInternet: "Spectrum, Earthlinnk, AT&T",
    gas: "https://www.countyoffice.org/hawkins-county-gas-utility-rogersville-tn-5a6/",
    trashRemoval: [ "https://tricitiessanitation.com/"],
    publicSchools: ["https://www.rcschool.net/", "https://www.hck12.net/", "https://www.greatschools.org/tennessee/rogersville/" ]
  },
  {
    name: "Russellville",
    county: "Hamblen County",
    zip: "37860",
    government: "https://www.russellvillechamber.com/government",
    demographics: "https://data.census.gov/profile/Russellville_CDP,_Tennessee?g=160XX00US4765740",
    electric: "https://www.aecoop.org/",
    waterSewer: "https://rwud.com/",
    sewer: "",
    cableInternet: "AT&T, Xfinity, Spectrum",
    gas: "",
    trashRemoval: [ "No Information" ],
    publicSchools: ["https://res.hcboe.net/", "https://www.hcboe.net/", "https://www.greatschools.org/tennessee/russellville/" ]
  },
  {
    name: "Rutledge (Incorporated)",
    county: "Grainger County",
    zip: "37861",
    government: "https://www.facebook.com/Rutledgecity/",
    demographics: "https://data.census.gov/profile/Rutledge_town,_Tennessee?g=160XX00US4765820",
    electric: "https://www.aecoop.org/",
    waterSewer: "No Information",
    sewer: "",
    cableInternet: "EarthLink, Frontier, Spectrum",
    gas: "",
    trashRemoval: [ "https://www.graingercountytn.com/county-officials/landfill/" ],
    publicSchools: ["https://www.grainger.k12.tn.us/", "https://www.greatschools.org/tennessee/rutledge/" ]
  },
  {
    name: "Sevierville (Incorporated)",
    county: "Sevier County",
    zip: "37862, 37863, 37876",
    government: "https://seviervilletn.org/index.php",
    demographics: "https://data.census.gov/profile/Sevierville_city,_Tennessee?g=160XX00US4767120",
    electric: "https://sces.net/billing/",
    waterSewer: "https://seviervilletn.org/index.php/government/departments/water-sewer.html",
    sewer: "",
    cableInternet: "AT&T, Spectrum, Xfinity",
    gas: "https://www.scudgas.org/",
    trashRemoval: [ "https://seviervilletn.org/index.php/solid-waste.html" ],
    publicSchools: ["https://www.sevier.org/", "https://www.greatschools.org/tennessee/sevierville/" ]
  },

  {
    name: "Seymour",
    county: "Blount County, Sevier County",
    zip: "37865",
    government: "No Website",
    demographics: "https://data.census.gov/profile/Seymour_CDP,_Tennessee?g=160XX00US4767200",
    electric: "https://sces.net/billing/",
    waterSewer: "https://www.scudgas.org/",
    sewer: "",
    cableInternet: "AT&T, Spectrum",
    gas: "",
    trashRemoval: [ "https://www.seviersolidwaste.com/conveniencecenters/" ],
    publicSchools: ["https://www.sevier.org/", "https://www.greatschools.org/tennessee/seymour/" ]
  },
  {
    name: "Sneedville (Incorporated)",
    county: "Hancock County",
    zip: "37869",
    government: "https://www.hancockcountytn.com/Sneedville-City-Government.php",
    demographics: "https://data.census.gov/profile/Sneedville_town,_Tennessee?g=160XX00US4769460",
    electric: "https://www.pve.coop/",
    waterSewer: "https://sneedvilleutilitydistrict.com/",
    sewer: "",
    cableInternet: "https://broadbandnow.com/Point-Broadband",
    gas: "",
    trashRemoval: [ "https://www.hancockcountytn.com/Sneedville-City-Government.php", "https://www.republicservices.com/locations/tennessee/sneedville-trash-pickup-and-recycling" ],
    publicSchools: ["https://hancockcountyschools.com/", "https://www.greatschools.org/tennessee/sneedville/hancock-county-school-district/" ]
  },
  {
    name: "Sunbright (Incorporated)",
    county: "Morgan County",
    zip: "37872",
    government: "https://www.cityofsunbright.net/",
    demographics: "https://data.census.gov/profile/Sunbright_city,_Tennessee?g=160XX00US4772140",
    electric: "https://plateauelectric.com/",
    waterSewer: "https://www.plateauutility.com/",
    sewer: "",
    cableInternet: "https://broadbandnow.com/Highland-HTC",
    gas: "",
    trashRemoval: [ "https://www.morgancountytn.gov/solid-waste-recycling/" ],
    publicSchools: ["https://sunbright.mcsed.net/", "https://www.mcsed.net/", "https://www.greatschools.org/tennessee/sunbright/1028-Sunbright-School/" ]
  },
  {
    name: "Surgoinsville (Incorporated)",
    county: "Hawkins County",
    zip: "37873",
    government: "https://www.facebook.com/people/Town-of-Surgoinsville-City-Hall/61552044273955/",
    demographics: "https://data.census.gov/profile/Surgoinsville_town,_Tennessee?g=160XX00US4772380",
    electric: "https://www.countyoffice.org/holston-electric-co-op-inc-church-hill-tn-5df/",
    waterSewer: "https://www.countyoffice.org/surgoinsville-utility-district-surgoinsville-tn-b77/",
    sewer: "",
    cableInternet: "https://broadbandnow.com/HolstonConnect",
    gas: "https://www.countyoffice.org/hawkins-county-gas-utility-rogersville-tn-5a6/",
    trashRemoval: [ "https://www.hawkinscountytn.gov/solid_waste.html", "https://tricitiessanitation.com/" ],
    publicSchools: ["https://ses.hck12.net/", "https://www.hck12.net/", "https://www.greatschools.org/tennessee/surgoinsville/486-Surgoinsville-Elementary-School/" ]
  },
  {
    name: "Sweetwater (Incorporated)",
    county: "Monroe County",
    zip: "37874",
    government: "https://www.sweetwatertn.gov/",
    demographics: "https://data.census.gov/profile/Sweetwater_city,_Tennessee?g=160XX00US4772540",
    electric: "https://www.sweetwaterutilities.com/",
    waterSewer: "https://www.sweetwaterutilities.com/",
    sewer: "",
    cableInternet: "AT&T, Spectrum",
    gas: "https://www.sweetwaterutilities.com/",
    trashRemoval: [ "https://dynamicwasteco.com/" ],
    publicSchools: ["https://www.sweetwatercityschools.com/", "https://www.monroe.k12.tn.us/", "https://www.greatschools.org/tennessee/sweetwater/" ]
  },
  {
    name: "Tazewell (Incorporated)",
    county: "Claiborne County",
    zip: "37879",
    government: "https://tazewelltn.net/",
    demographics: "https://data.census.gov/profile/Tazewell_town,_Tennessee?g=160XX00US4773120",
    electric: "https://www.lub.org/rates/electric-rates/",
    waterSewer: "https://www.asudtn.com/",
    sewer: "",
    cableInternet: "https://broadbandnow.com/mountainet",
    gas: "",
    trashRemoval: [ "https://claibornecountytn.gov/countyoffices/county-services/sanitation/" ],
    publicSchools: ["http://www.claibornecountyschools.com/", "https://www.facebook.com/ClaiborneCountySchools/", "https://www.greatschools.org/tennessee/tazewell/claiborne-county-school-district/" ],
    MovingInfo: [ "https://www.movingideas.org/tazewell-tn/" ]
  },
  {
    name: "Telford",
    county: "Washington County",
    zip: "37690",
    government: "https://www.washingtoncountytn.org/",
    demographics: "https://data.census.gov/profile/Telford_CDP,_Tennessee?g=160XX00US4773220",
    electric: "https://www.brightridge.com/",
    waterSewer: "https://www.johnsoncitytn.org/water_sewer_services.php",
    sewer: "",
    cableInternet: "Xfinity, BrightSpeed",
    gas: "",
    trashRemoval: [ "https://www.washingtoncountytn.org/195/Solid-Waste", "https://www.republicservices.com/locations/tennessee/telford-trash-pickup-and-recycling" ],
    publicSchools: ["https://www.wcde.org/", "https://www.publicschoolreview.com/tennessee/telford" ]
  },
  {
    name: "Tellico Plains (Incorporated)",
    county: "Monroe County",
    zip: "37385",
    government: "https://www.tellicoplainstn.com/city-gov",
    demographics: "https://data.census.gov/profile/Tellico_Plains_town,_Tennessee?g=160XX00US4773260",
    electric: "https://www.flec.org/",
    waterSewer: "https://www.tellicoplainstn.com/water",
    sewer: "",
    cableInternet: "https://broadbandnow.com/TDS-Telecom",
    gas: "",
    trashRemoval: [ "https://monroetn.gov/solid-waste/" ],
    publicSchools: ["https://www.monroe.k12.tn.us/", "https://www.greatschools.org/tennessee/tellico-plains/" ]
  },
  {
    name: "Townsend (Incorporated)",
    county: "Blount County",
    zip: "37882",
    government: "https://www.cityoftownsend.com/wp/",
    demographics: "https://data.census.gov/profile/Townsend_city,_Tennessee?g=160XX00US4774860",
    electric: "https://www.cityoftownsend.com/wp/elementor-8351/",
    waterSewer: "https://www.cityoftownsend.com/wp/residents/operations-division/",
    sewer: "",
    cableInternet: "Xfinity, EarthLink, AT&T",
    gas: "",
    trashRemoval: [ "https://www.cityoftownsend.com/wp/residents/operations-division/" ],
    publicSchools: ["https://www.blountk12.org/", "https://www.greatschools.org/tennessee/townsend/" ]
  },
  {
    name: "Tusculum (Incorporated)",
    county: "Greene County",
    zip: "37745",
    government: "https://tusculumcity.org/",
    demographics: "https://data.census.gov/profile/Tusculum_city,_Tennessee?g=160XX00US4775560",
    electric: "https://www.brightridge.com/",
    waterSewer: "https://rwud.com/",
    sewer: "",
    cableInternet: "Xfinity",
    gas: "",
    trashRemoval: [ "https://tricitiessanitation.com/" ],
    publicSchools: ["https://tusculumcity.org/schools/", "https://www.greenek12.org/", "https://www.greatschools.org/tennessee/greeneville/greene-county-school-district/" ]
  },
  {
    name: "Unicoi (Incorporated)",
    county: "Unicoi County",
    zip: "37692",
    government: "https://unicoitn.net/",
    demographics: "https://data.census.gov/profile/Unicoi_town,_Tennessee?g=160XX00US4775820",
    electric: "https://www.eesonline.org/",
    waterSewer: "https://www.unicoiwater.com/",
    sewer: "",
    cableInternet: "https://www.e-u.cc/fiber/",
    gas: "",
    trashRemoval: [ "https://unicoicountytn.com/solid-waste-recycling/" ],
    publicSchools: ["https://www.unicoischools.com/", "https://www.greatschools.org/tennessee/unicoi/" ]
  },
  {
    name: "Vonore (Incorporated)",
    county: "Monroe County",
    zip: "37885",
    government: "https://www.town-of-vonore.com/",
    demographics: "https://data.census.gov/profile/Vonore_town,_Tennessee?g=160XX00US4777480",
    electric: "https://www.flec.org/",
    waterSewer: "https://tassonline.org/water/",
    sewer: "",
    cableInternet: "https://broadbandnow.com/TDS-Telecom",
    gas: "",
    trashRemoval: [ "https://dynamicwasteco.com/" ],
    publicSchools: ["https://www.monroe.k12.tn.us/", "https://www.greatschools.org/tennessee/vonore/" ]
  },
  {
    name: "Wartburg (Incorporated)",
    county: "Morgan County",
    zip: "37887",
    government: "https://www.facebook.com/CityofWartburg/",
    demographics: "https://data.census.gov/profile/Wartburg_city,_Tennessee?g=160XX00US4778100",
    electric: "https://plateauelectric.com/",
    waterSewer: "https://www.plateauutility.com/",
    sewer: "",
    cableInternet: "https://broadbandnow.com/Highland-HTC",
    gas: "",
    trashRemoval: [ "https://www.morgancountytn.gov/solid-waste-recycling/", "https://www.republicservices.com/locations/tennessee/wartburg-trash-pickup-and-recycling" ],
    publicSchools: ["https://www.mcsed.net/", "https://www.greatschools.org/tennessee/wartburg/" ]
  },
  {
    name: "Watauga (Incorporated)",
    county: "Carter County, Washington County",
    zip: "37694",
    government: "https://cityofwatauga.org/",
    demographics: "https://data.census.gov/profile/Watauga_city,_Tennessee?g=160XX00US4778240",
    electric: "https://www.brightridge.com/",
    waterSewer: "Unknown",
    sewer: "",
    cableInternet: "Spectrum, BrightSpeed",
    gas: "",
    trashRemoval: [ "https://cityofwatauga.org/services/" ],
    publicSchools: ["https://www.wcde.org/", "https://www.carterk12.org/", "https://www.publicschoolreview.com/tennessee/washington-county" ]
  },
  {
    name: "White Pine (Incorporated)",
    county: "Jefferson County",
    zip: "37890",
    government: "https://whitepinetn.gov/",
    demographics: "https://data.census.gov/profile/White_Pine_town,_Tennessee?g=160XX00US4780360",
    electric: "https://www.aecoop.org/",
    waterSewer: "https://whitepinetn.gov/public-works/",
    sewer: "",
    cableInternet: "Spectrum, EarthLink, AT&T",
    gas: "https://www.jccud.com/",
    trashRemoval: [ "" ],
    publicSchools: ["https://whitepinetn.gov/get-to-know-the-schools-of-white-pine-tn/", "https://www.publicschoolreview.com/tennessee/white-pine" ]
  }
];
export default EastTNCities;
