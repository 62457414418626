import React from 'react';
import './StateGov.css';
import { Helmet } from 'react-helmet';

const StateGov = () => {
  return (
    <div className="state-gov-page">
      <Helmet>
        <title>State Government - Relocate-TN</title>
        <meta name="description" content="Information about the Tennessee State Government, facts, and services." />
        <link href="https://fonts.googleapis.com/css2?family=Pacifico&display=swap" rel="stylesheet" />
      </Helmet>

      {/* Interesting Facts Section */}
      <section className="state-facts">
        <h2>Interesting Tennessee Facts:</h2>
        <ul>
          <li>Tennessee was the 16th state to join the union in 1796</li>
          <li>Tennessee is bordered by eight states (AL, AR, GA, KY, MS, MO, NC, and VA)</li>
          <li>Tennessee is the 15th most populous state out of the 50 US states</li>
          <li>Tennessee provided more soldiers during the War of 1812 than any other state, leading to it being called “The Volunteer State”</li>
          <li>Tennessee’s Great Smoky Mountain National Park is the most visited national park in the US</li>
        </ul>
      </section>

      {/* State Information Section */}
      <section className="state-info">
        <div className="state-image">
          {/* Your image will go here */}
        </div>
        <div className="overlay-text-state">
          <h2>State Information</h2>
          <ul>
            <li>State Website: <a href="https://www.tn.gov/" target="_blank" rel="noopener noreferrer">State Website Link</a></li>
            <li>Driver’s License/Vehicle Registration: <a href="https://www.tn.gov/" target="_blank" rel="noopener noreferrer">Driver’s License</a></li>
            <li>Taxes: <a href="https://comptroller.tn.gov/" target="_blank" rel="noopener noreferrer">Taxes Link</a></li>
            <li>Professional Licensing: <a href="https://www.tn.gov/business/licenses-permits.html" target="_blank" rel="noopener noreferrer">Licensing Link</a></li>
            <li>Rules and Regulations: <a href="https://sos.tn.gov/publications/services/effective-rules-and-regulations-of-the-state-of-tennessee" target="_blank" rel="noopener noreferrer">Rules and Regulations Link</a></li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default StateGov;
