import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';  // Import Helmet for meta data management
import './Blog.css';

const articles = [
    {
        id: 4,
        title: "Looking for Vacant Land in Tennessee",
        author: "Relocate-TN.com Admin",
        date: "September 7, 2024",
        image: `${process.env.PUBLIC_URL}/VacantLandTN.png`,
        excerpt: "Photos of rolling hills, mountain vistas, and lakeside retreats cause most of us to yearn for that special kind of place...",
        path: "/blog/posts/LookingForVacantLand"
    },
    {
        id: 5,
        title: "Tennessee Taxes",
        author: "Relocate-TN.com Admin",
        date: "September 7, 2024",
        image: `${process.env.PUBLIC_URL}/Taxes.png`,
        excerpt: "Explore the tax benefits of living in Tennessee, including no state income tax and low property taxes...",
        path: "/blog/posts/TennesseeTaxes"
    },
    {
        id: 6,
        title: "Tennessee Climate",
        author: "Relocate-TN.com Admin",
        date: "September 8, 2024",
        image: `${process.env.PUBLIC_URL}/Climate.png`,
        excerpt: "Tennessee’s climate offers four distinct seasons and a pleasant balance of warm summers and mild winters...",
        path: "/blog/posts/TennesseeClimate"
    },
    
];

const Blog = () => {
    const [visibleArticles, setVisibleArticles] = useState(3);

    const handleLoadMore = () => {
        setVisibleArticles((prev) => prev + 3); // Show 3 more articles
    };

    return (
        <div className="blog-hero">
            {/* Meta data for SEO */}
            <Helmet>
                <title>Relocate-TN Blog - Latest Articles and Insights</title>
                <meta 
                    name="description" 
                    content="Read the latest articles and insights on relocating to Tennessee, including taxes, climate, and vacant land opportunities." 
                />
                <meta 
                    name="keywords" 
                    content="Tennessee, relocation, vacant land, taxes, climate, Relocate-TN blog, living in Tennessee" 
                />
            </Helmet>

            <h1 className="blog-title">Our Latest Articles</h1>
            <div className="blog-articles-hero">
                {articles.slice(0, visibleArticles).map((article) => (
                    <Link to={article.path} key={article.id} className="blog-article-card">
                        <img src={article.image} alt={article.title} className="blog-article-image" />
                        <div className="blog-article-content">
                            <h2>{article.title}</h2>
                            <p>{article.excerpt}</p>
                            <p className="blog-article-meta">By {article.author} | {article.date}</p>
                        </div>
                    </Link>
                ))}
            </div>
            {visibleArticles < articles.length && (
                <button className="blog-load-more" onClick={handleLoadMore}>
                    Load More
                </button>
            )}
        </div>
    );
};

export default Blog;
