import React from 'react';
import './Article.css';

const WhyTennessee = () => {
    return (
        <div className="article-page">
            <h1>Why Tennessee Might Be the Best State You've Never Considered Moving To</h1>
            <p className="article-meta">By John Doe | September 4, 2024</p>
            <img src={`${process.env.PUBLIC_URL}/Knoxville.jpg`} alt="Why Tennessee" className="article-image"/>
            <p>Tennessee offers a unique blend of natural beauty, economic opportunity, and a high quality of life...</p>
            {/* Add more content here */}
        </div>
    );
};

export default WhyTennessee;
