import React, { useState } from 'react';
import './Counties.css';
import { Helmet } from 'react-helmet';
import EastTNCounties from './EastTNCounties'; // Importing the counties data

const Counties = () => {
  const [selectedRegion, setSelectedRegion] = useState('east');

  const handleRegionChange = (region) => {
    setSelectedRegion(region);
    const section = document.getElementById(region);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="counties-page">
      <Helmet>
        <title>Explore Tennessee Counties - Relocate-TN</title>
        <meta name="description" content="Explore Tennessee counties and their government services." />
      </Helmet>
      
      <section className="hero-counties">
        <div className="counties-image">
          {/* Your image will go here */}
        </div>
        <div className="overlay-text">
          <h2>Counties in Tennessee are typically responsible for the following services:</h2>
          <ul>
            <li>Property Taxes/ Property Tax Appraisals</li>
            <li>Drivers Licenses</li>
            <li>Vehicle Registration</li>
            <li>Licensing (Marriage/ Business)</li>
            <li>Voter Registration</li>
            <li>Zoning & Permitting</li>
            <li>Parks & Recreation</li>
            <li>Health Department</li>
            <li>County Sheriff/ Fire Protection</li>
            <li>Court Clerk/ Public Records</li>
          </ul>
        </div>
      </section>

      {/* Region Selector */}
      <div className="region-selector">
        <button onClick={() => handleRegionChange('east')}>East Tennessee</button>
        <button onClick={() => handleRegionChange('middle-east')}>Middle East Tennessee</button>
        <button onClick={() => handleRegionChange('middle-west')}>Middle West Tennessee</button>
        <button onClick={() => handleRegionChange('west')}>West Tennessee</button>
      </div>
      
      {/* Section for each region */}
      <section className="county-section" id="east">
        <h2 className="county-title">East Tennessee Counties</h2>
        {EastTNCounties.map((county, index) => (
          <div className="county-item" key={index}>
            <h3>{county.name} County Government <a href={county.government} target="_blank" rel="noopener noreferrer">Government</a></h3>
            <p>Demographics: <a href={county.demographics} target="_blank" rel="noopener noreferrer">Demographics</a></p>
            <p>Cities: {county.cities.join(", ")}</p>
          </div>
        ))}
      </section>

      <section className="county-section" id="middle-east">
        <h2 className="county-title">Middle East Tennessee Counties</h2>
        <p className="coming-soon">Coming Soon</p>
      </section>

      <section className="county-section" id="middle-west">
        <h2 className="county-title">Middle West Tennessee Counties</h2>
        <p className="coming-soon">Coming Soon</p>
      </section>

      <section className="county-section" id="west">
        <h2 className="county-title">West Tennessee Counties</h2>
        <p className="coming-soon">Coming Soon</p>
      </section>
    </div>
  );
};

export default Counties;
