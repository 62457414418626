import React, { useState } from 'react';
import { FaHome, FaStar, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa'; // Importing icons
import './RealtorRequest.css';  
import { Helmet } from 'react-helmet';
import emailjs from '@emailjs/browser';  // Import EmailJS
import EastTNCities from './EastTNCities';  // Importing cities data
import EastTNCounties from './EastTNCounties';  // Importing counties data

const RealtorRequest = () => {
  const [city, setCity] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [vacantLand, setVacantLand] = useState('');
  const [acres, setAcres] = useState('');
  const [house, setHouse] = useState('');
  const [bedrooms, setBedrooms] = useState('');
  const [timeframe, setTimeframe] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [contactPreference, setContactPreference] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);  // For error handling

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      'service_ofon8s9',    // Replace with your EmailJS Service ID
      'template_3nozuhs',   // Replace with your EmailJS Template ID
      e.target,             // The form
      'nV96yKTRO0VMybmhK'        // Replace with your EmailJS User ID (public key)
    )
    .then((result) => {
      setSubmitted(true);
      setError(null); // Reset the error state
    }, (error) => {
      setError('Error submitting request. Please try again.');
    });
  };

  const handleCityInputChange = (e) => {
    const userInput = e.target.value;
    setCity(userInput);

    if (userInput) {
      const citySuggestions = EastTNCities.filter(item =>
        item.name.toLowerCase().startsWith(userInput.toLowerCase())
      );

      const countySuggestions = EastTNCounties.filter(item =>
        item.name.toLowerCase().startsWith(userInput.toLowerCase())
      );

      const suggestions = [...citySuggestions, ...countySuggestions];

      setFilteredSuggestions(suggestions);
    } else {
      setFilteredSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setCity(suggestion.name);
    setFilteredSuggestions([]);
  };

  return (
    <div className="realtor-request-page">
      <Helmet>
        <title>Realtor Request - Relocate-TN</title>
        <meta name="description" content="Find a realtor to help you with your search for property in East Tennessee." />
        <meta name="keywords" content="real estate, realtor, Tennessee, East Tennessee, property search, homes, land" />
      </Helmet>

      <section className="hero-realtor">
        <div className="realtor-image-left">
          {/* Your Realtor.png image will go here */}
        </div>
        <div className="overlay-text-realtor-right">
          <h2 style={{ fontStyle: 'italic' }}>Time to Start Looking!</h2>
          <p>Tennessee has so much to offer from the Appalachian mountains to rolling hills and golden plains. Thousands of acres of vacant land offer amazing views, serene mountain brooks, and river vistas. Homes range from quaint old farmhouses to modern energy-efficient developments. The first step in the process is to work with a knowledgeable real estate professional who can help you locate your dream home and walk you through the complicated purchase process.</p>
          <p>By entering information in the form below, you authorize us to share your information with a local real estate agent who may contact you. We will not share your information with any other entity, forum, or business.</p>
          <p>Happy hunting!</p>
        </div>
      </section>

      {/* Featured Realtors Section */}
<section className="realtor-form-featured">
  <div className="realtor-card">
    <div className="realtor-card-title">
      <FaStar className="realtor-card-icon" />
      Featured Realtor
    </div>
    <div className="realtor-card-content">
      <p>Lucy Hood </p>
      <p><FaEnvelope /> lucyhood@kw.com</p>
      <p>Cell: (713) 504-2535</p>
      <p>Office: (865) 999-8601</p>
      <p>License: 335514</p>
    </div>
  </div>

  <div className="realtor-card">
    <div className="realtor-card-title">
      <FaStar className="realtor-card-icon" />
      Featured Realtor
    </div>
    <div className="realtor-card-content">
      <p>Valerie Everroad</p>
      <p><FaEnvelope /> valerieeverroad@gmail.com</p>
      <p>Cell: (423) 292-1792</p>
      <p>Office: (423) 282-1817</p>
      <p>License: 359318</p>
    </div>
  </div>
</section>


      <section className="realtor-form-section">
        {submitted ? (
          <div className="realtor-thank-you-message">
            <h3>Thank you for your request!</h3>
            <p>A local real estate agent will contact you shortly to assist with your property search.</p>
          </div>
        ) : (
          <form className="realtor-form" onSubmit={handleSubmit}>
            <div className="realtor-form-title">
              <FaHome className="realtor-form-title-icon" />
              <h3>Realtor Request</h3>
            </div>
            <input
              type="text"
              name="city"
              value={city}
              onChange={handleCityInputChange}
              placeholder="Enter the nearest city or county"
              required
            />
            {filteredSuggestions.length > 0 && (
              <ul className="suggestions-list">
                {filteredSuggestions.map((suggestion, index) => (
                  <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                    {suggestion.name}
                  </li>
                ))}
              </ul>
            )}
            <label>Are you looking for vacant land to build on?</label>
            <select name="vacantLand" value={vacantLand} onChange={(e) => setVacantLand(e.target.value)} required>
              <option value="">Select...</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
            {vacantLand === 'yes' && (
              <input
                type="number"
                name="acres"
                value={acres}
                onChange={(e) => setAcres(e.target.value)}
                placeholder="How many acres?"
                min="0.1"
                step="0.1"
                max="100"
              />
            )}
            <label>Are you looking for an existing house?</label>
            <select name="house" value={house} onChange={(e) => setHouse(e.target.value)} required>
              <option value="">Select...</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
            <select name="bedrooms" value={bedrooms} onChange={(e) => setBedrooms(e.target.value)} required>
              <option value="">How many bedrooms?</option>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10+">10+</option>
            </select>
            <label>What is your timeframe for making a purchase?</label>
            <select name="timeframe" value={timeframe} onChange={(e) => setTimeframe(e.target.value)} required>
              <option value="">Select...</option>
              <option value="ASAP">ASAP</option>
              <option value="30 Days">30 Days</option>
              <option value="60 Days">60 Days</option>
              <option value="90 Days">90 Days</option>
              <option value="120 Days">120 Days</option>
              <option value="180 Days">180 Days</option>
            </select>
            <input
              type="text"
              name="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="First Name"
              required
            />
            <input
              type="text"
              name="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Last Name"
              required
            />
            <input
              type="tel"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Your Phone Number"
              required
            />
            <input
              type="email"
              name="useremail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Your Email"
              required
            />

            <label>Contact Preference</label>
            <select name="contactPreference" value={contactPreference} onChange={(e) => setContactPreference(e.target.value)} required>
              <option value="">Select...</option>
              <option value="email">Email</option>
              <option value="phone">Phone</option>
              <option value="both">Both</option>
            </select>

            <button type="submit">Submit Request</button>
            {error && <p className="error-message">{error}</p>}
          </form>
        )}
      </section>
    </div>
  );
};

export default RealtorRequest;
