import React, { useState } from 'react';
import { FaMountain } from 'react-icons/fa';
import './BuilderRequest.css';  
import { Helmet } from 'react-helmet';
import emailjs from '@emailjs/browser';  // Import EmailJS
import EastTNCities from './EastTNCities';  // Importing cities data
import EastTNCounties from './EastTNCounties';  // Importing counties data

const BuilderRequest = () => {
  const [city, setCity] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [vacantLand, setVacantLand] = useState('');
  const [house, setHouse] = useState('');
  const [timeframe, setTimeframe] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [contactPreference, setContactPreference] = useState(''); 
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);  // Error handling for form submission

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      'service_ofon8s9',    // Replace with your EmailJS Service ID
      'template_sod1qrd',   // Replace with your EmailJS Template ID
      e.target,             // The form
      'nV96yKTRO0VMybmhK'        // Replace with your EmailJS User ID (public key)
    )
    .then((result) => {
      setSubmitted(true);
      setError(null);  // Reset error state on successful submission
    }, (error) => {
      setError('Error submitting the application. Please try again.');
    });
  };

  const handleCityInputChange = (e) => {
    const userInput = e.target.value;
    setCity(userInput);

    if (userInput) {
      const citySuggestions = EastTNCities.filter(item =>
        item.name.toLowerCase().startsWith(userInput.toLowerCase())
      );

      const countySuggestions = EastTNCounties.filter(item =>
        item.name.toLowerCase().startsWith(userInput.toLowerCase())
      );

      const suggestions = [...citySuggestions, ...countySuggestions];

      setFilteredSuggestions(suggestions);
    } else {
      setFilteredSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setCity(suggestion.name);
    setFilteredSuggestions([]);
  };

  return (
    <div className="builder-request-page">
      <Helmet>
        <title>Builder Request - Relocate-TN</title>
        <meta name="description" content="Find a builder to help you with your construction projects in East Tennessee." />
        <meta name="keywords" content="construction, builder, Tennessee, East Tennessee, property, homes, land" />
      </Helmet>

      <section className="hero-builder">
        <div className="builder-image-left">
          {/* Your Builder.png image will go here */}
        </div>
        <div className="overlay-text-builder-right">
          <h2 style={{ fontStyle: 'italic' }}>Time to Start Building!</h2>
          <p>Tennessee has so much to offer, from the Appalachian Mountains in the southeastern part of the region to the rolling hills in the northwest. Thousands of acres of vacant land offer amazing views, serene mountain brooks, and river vistas. Whether you’re looking to build a modern energy-efficient home or a quaint farmhouse, the first step in the process is to work with a knowledgeable, licensed builder who can help you create your dream home and guide you through the construction process.</p>
          <p>By entering information in the form below, you authorize us to share your information with a local builder who may contact you. We will not share your information with any other entity, forum, or business.</p>
          <p>Happy building!</p>
        </div>
      </section>

      <section className="builder-form-section">
        {submitted ? (
          <div className="thank-you-message">
            <h3>Thank you for your request!</h3>
            <p>A local builder will contact you shortly to assist with your construction project.</p>
          </div>
        ) : (
          <form className="builder-form" onSubmit={handleSubmit}>
            <div className="builder-form-title">
              <FaMountain className="builder-form-title-icon" />
              <h3>Builder Request</h3>
            </div>
            <input
              type="text"
              name="city"  // Added name attribute for EmailJS
              value={city}
              onChange={handleCityInputChange}
              placeholder="Enter the nearest city or county"
              required
            />
            {/* Display filtered suggestions */}
            {filteredSuggestions.length > 0 && (
              <ul className="suggestions-list">
                {filteredSuggestions.map((suggestion, index) => (
                  <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                    {suggestion.name}
                  </li>
                ))}
              </ul>
            )}

            <label>Are you looking to build a home or rental cabin?</label>
            <select name="house" value={house} onChange={(e) => setHouse(e.target.value)} required>
              <option value="">Select...</option>
              <option value="home">Home</option>
              <option value="cabin">Rental Cabin</option>
            </select>

            <label>Do you currently have house plans drawn or purchased?</label>
            <select name="vacantLand" value={vacantLand} onChange={(e) => setVacantLand(e.target.value)} required>
              <option value="">Select...</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>

            <label>What is your timeframe for starting the build?</label>
            <select name="timeframe" value={timeframe} onChange={(e) => setTimeframe(e.target.value)} required>
              <option value="">Select...</option>
              <option value="ASAP">ASAP</option>
              <option value="30 Days">30 Days</option>
              <option value="60 Days">60 Days</option>
              <option value="90 Days">90 Days</option>
              <option value="120 Days">120 Days</option>
              <option value="180 Days">180 Days</option>
            </select>

            <input
              type="text"
              name="firstName"  // Added name attribute for EmailJS
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="First Name"
              required
            />
            <input
              type="text"
              name="lastName"  // Added name attribute for EmailJS
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Last Name"
              required
            />
            <input
              type="tel"
              name="phone"  // Added name attribute for EmailJS
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Your Telephone Number"
              required
            />
            <input
              type="email"
              name="email"  // Added name attribute for EmailJS
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Your Email"
              required
            />

            <label>Contact Preference</label>
            <select name="contactPreference" value={contactPreference} onChange={(e) => setContactPreference(e.target.value)} required>
              <option value="">Select...</option>
              <option value="email">Email</option>
              <option value="phone">Phone</option>
              <option value="both">Both</option>
            </select>

            <button type="submit">Submit Request</button>
            {error && <p className="builder-form-error">{error}</p>}
          </form>
        )}
      </section>
    </div>
  );
};

export default BuilderRequest;
