import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './HamburgerMenu.css';

const HamburgerMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.bm-item-list') && !event.target.closest('.hamburger-menu')) {
        closeMenu();
      }
    };

    if (menuOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [menuOpen]);

  const handleNavigation = (path) => {
    navigate(path);
    closeMenu();
  };

  return (
    <div className="hamburger-menu">
      <div className={`bar ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}></div>
      <div className={`bar ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}></div>
      <div className={`bar ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}></div>

      <nav className={`bm-item-list ${menuOpen ? 'open' : ''}`}>
        <Link to="/counties" className="menu-item" onClick={() => handleNavigation('/counties')}>TN Counties</Link>
        <Link to="/cities" className="menu-item" onClick={() => handleNavigation('/cities')}>TN Cities</Link>
        <Link to="/government" className="menu-item" onClick={() => handleNavigation('/government')}>State Government</Link>
        <Link to="/realtor" className="menu-item" onClick={() => handleNavigation('/realtor')}>Request Realtor</Link>
        {/* <Link to="/builder" className="menu-item" onClick={() => handleNavigation('/builder')}>Request Builder</Link> */} {/* Hidden builder link */}
        <Link to="/application" className="menu-item" onClick={() => handleNavigation('/application')}>Service Providers</Link>
        <Link to="/blog" className="menu-item" onClick={() => handleNavigation('/blog')}>TN Articles</Link>
        <Link to="/feedback" className="menu-item" onClick={() => handleNavigation('/feedback')}>Feedback</Link>
      </nav>
    </div>
  );
};

export default HamburgerMenu;
