import React from 'react';
import './Article.css';

const Knoxville = () => {
    return (
        <div className="article-page">
            <h1>Why Moving to Knoxville, TN Could Be the Best Decision You Ever Make</h1>
            <p className="article-meta">By Jane Smith | September 5, 2024</p>
            <img src={`${process.env.PUBLIC_URL}/Knoxville.jpg`} alt="Knoxville" className="article-image"/>
            <p>Knoxville is a hidden gem that combines the charm of a small town with the amenities of a larger city...</p>
            {/* Add more content here */}
        </div>
    );
};

export default Knoxville;
