import React from 'react';
import './Hero.css';

const Hero = () => {
  return (
    <section className="hero">
      <div className="hero-text">
        <h1>Beautiful Land, Beautiful People, A Beautiful Life</h1>
      </div>
      <div className="image-bg"></div>
    </section>
  );
};

export default Hero;
