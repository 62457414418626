import React, { useState } from 'react';
import './Feedback.css';
import { Helmet } from 'react-helmet';
import emailjs from '@emailjs/browser';

const Feedback = () => {
  const [feedback, setFeedback] = useState('');
  const [email, setEmail] = useState('');  
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      'service_ofon8s9',      // Replace with your EmailJS Service ID
      'template_8llle9s',          // Replace with your EmailJS Template ID
      e.target,               // The form
      'nV96yKTRO0VMybmhK'          // Replace with your EmailJS User ID (public key)
    )
    .then((result) => {
      setSubmitted(true);
      setError(null);
    }, (error) => {
      setError('Error submitting feedback. Please try again.');
    });
  };

  return (
    <div className="feedback-page">
      <Helmet>
        <title>Feedback - Relocate-TN</title>
        <meta name="description" content="Please provide feedback to help improve the Relocate-TN website." />
        <meta name="keywords" content="feedback, relocate-TN, Tennessee, website, suggestions, improvements" />
        <meta property="og:title" content="Feedback - Relocate-TN" />
        <meta property="og:description" content="Your feedback is valuable to us. Help us improve the Relocate-TN website." />
        <meta property="og:image" content="https://example.com/feedback-image.png" />
        <meta property="og:url" content="https://relocate-tn.com/feedback" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Feedback - Relocate-TN" />
        <meta name="twitter:description" content="Your feedback is valuable to us. Help us improve the Relocate-TN website." />
        <meta name="twitter:image" content="https://example.com/feedback-image.png" />
      </Helmet>

      <section className="hero-feedback">
        <div className="overlay-text-left">
          <h2>Please Help Us to Improve!</h2>
          <p>Your feedback is very important to us. Please let us know in the space below if the information provided was helpful. If there is missing or incorrect information, please indicate that so that we can make this website better.</p>
        </div>
        <div className="feedback-image-right">
          {/* Your image will go here */}
        </div>
      </section>

      <section className="feedback-form-section">
        {submitted ? (
          <div className="thank-you-message">
            <h3>Thank you for your feedback!</h3>
            <p>We appreciate your input and will use it to improve our website.</p>
          </div>
        ) : (
          <form className="feedback-form" onSubmit={handleSubmit}>
            <textarea
              name="feedback"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              placeholder="Please provide your feedback here..."
              required
            />
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Your Email (Optional)"
            />
            <button type="submit">Submit Feedback</button>
            {error && <p className="error-message">{error}</p>}
          </form>
        )}
      </section>
    </div>
  );
};

export default Feedback;
