const EastTNCounties = [
    {
      name: "Anderson",
      government: "https://andersoncountytn.gov",
      demographics: "https://data.census.gov/profile/Anderson_County,_Tennessee?g=050XX00US47001",
      cities: ["Andersonville", "Briceville", "Clinton", "Norris", "Oakridge", "Oliver Springs", "Rocky Top"]
    },
    {
      name: "Blount",
      government: "https://blounttn.gov",
      demographics: "https://data.census.gov/profile/Blount_County,_Tennessee?g=050XX00US47009",
      cities: ["Alcoa", "Eagleton Village", "Friendsville", "Louisville", "Maryville", "Rockford", "Seymour", "Townsend", "Walland", "Wildwood"]
    },
    {
      name: "Campbell",
      government: "https://campbellcountytn.gov/visitors/",
      demographics: "https://data.census.gov/profile/Campbell_County,_Tennessee?g=050XX00US47013",
      cities: ["Caryville", "Fincastle", "Jacksboro", "Jellico", "LaFollette", "Rocky Top"]
    },
    {
      name: "Carter",
      government: "https://www.cartercountytn.gov/government/officials/county-clerk/",
      demographics: "https://data.census.gov/profile/Carter_County,_Tennessee?g=050XX00US47019",
      cities: ["Biltmore", "Butler", "Central", "Elizabethton", "Hampton", "Hunter", "Johnson City", "Pine Crest", "Roan Mountain", "Valley Forge", "Watauga"]
    },
    {
      name: "Claiborne",
      government: "https://claibornecountytn.gov/",
      demographics: "https://data.census.gov/profile/Claiborne_County,_Tennessee?g=050XX00US47025",
      cities: ["Cumberland Gap", "Harrogate", "New Tazwell", "Tazewell"]
    },
    {
      name: "Cocke",
      government: "https://www.cockecountytn.gov/",
      demographics: "https://data.census.gov/profile/Cocke_County,_Tennessee?g=050XX00US47029",
      cities: ["Cosby", "Newport", "Parrottsville"]
    },
    {
      name: "Grainger",
      government: "https://www.graingercountytn.com/",
      demographics: "https://data.census.gov/profile/Grainger_County,_Tennessee?g=050XX00US47057",
      cities: ["Bean Station", "Blaine", "Rutledge"]
    },
    {
      name: "Greene",
      government: "https://www.greenecountytngov.com/",
      demographics: "https://data.census.gov/profile/Greene_County,_Tennessee?g=050XX00US47059",
      cities: ["Baileytown", "Fall Branch", "Greeneville", "Mosheim", "Tusculum"]
    },
    {
      name: "Hamblen",
      government: "https://www.hamblencountytn.gov/",
      demographics: "https://data.census.gov/profile/Hamblen_County,_Tennessee?g=050XX00US47063",
      cities: ["Morristown", "Russellville"]
    },
    {
      name: "Hancock",
      government: "http://www.hancockcountytn.com/",
      demographics: "https://data.census.gov/profile/Hancock_County,_Tennessee?g=050XX00US47067",
      cities: ["Sneedville"]
    },
    {
      name: "Hawkins",
      government: "https://hawkinscountytn.gov/",
      demographics: "https://data.census.gov/profile/Hawkins_County,_Tennessee?g=050XX00US47073",
      cities: ["Bulls Gap", "Church Hill", "Kingsport", "Mooresburg", "Mount Carmel", "Rogersville", "Surgoinsville"]
    },
    {
      name: "Jefferson",
      government: "https://jeffersoncountytn.gov/",
      demographics: "https://data.census.gov/profile/Jefferson_County,_Tennessee?g=050XX00US47089",
      cities: ["Baneberry", "Dandridge", "Jefferson City", "Morristown", "New Market", "Strawberry Plains", "White Pine"]
    },
    {
      name: "Johnson",
      government: "https://www.johnsoncountytn.gov/",
      demographics: "https://data.census.gov/profile/Johnson_County,_Tennessee?g=050XX00US47091",
      cities: ["Butler", "Mountain City"]
    },
    {
      name: "Knox",
      government: "https://www.knoxcounty.org/",
      demographics: "https://data.census.gov/profile/Knox_County,_Tennessee?g=050XX00US47093",
      cities: ["Farragut", "Halls", "John Sevier", "Karns", "Knoxville", "Mascot", "Powell"]
    },
    {
      name: "Loudon",
      government: "https://www.loudoncounty-tn.gov/",
      demographics: "https://data.census.gov/profile/Loudon_County,_Tennessee?g=050XX00US47105",
      cities: ["Greenback", "Lenoir City", "Loudon", "Philadelphia", "Rarity Bay", "Tellico Village"]
    },
    {
      name: "Monroe",
      government: "https://monroetn.com/",
      demographics: "https://data.census.gov/profile/Monroe_County,_Tennessee?g=050XX00US47123",
      cities: ["Coker Creek", "Kahite", "Madisonville", "Rarity Bay", "Sweetwater", "Tellico Plains", "Vonore"]
    },
    {
      name: "Morgan",
      government: "https://www.morgancountytn.gov/",
      demographics: "https://data.census.gov/profile/Morgan_County,_Tennessee?g=050XX00US47129",
      cities: ["Coalfield", "Oakdale", "Oliver Springs", "Petros", "Sunbright", "Wartburg"]
    },
    {
      name: "Sevier",
      government: "https://seviercountytn.gov/",
      demographics: "https://data.census.gov/profile/Sevier_County,_Tennessee?g=050XX00US47155",
      cities: ["Fair Garden", "Gatlinburg", "Pigeon Forge", "Pittman Center", "Sevierville", "Seymour"]
    },
    {
      name: "Sullivan",
      government: "https://sullivancountytn.gov/",
      demographics: "https://data.census.gov/profile/Sullivan_County,_Tennessee?g=050XX00US47163",
      cities: ["Bloomingdale", "Blountville", "Bluff City", "Bristol", "Colonial Heights", "Johnson City", "Kingsport", "Orebank", "Spurgeon", "Sullivan Gardens", "Walnut Hill"]
    },
    {
      name: "Unicoi",
      government: "https://unicoicountytn.com/",
      demographics: "https://data.census.gov/profile/Unicoi_County,_Tennessee?g=050XX00US47171",
      cities: ["Banner Hill", "Erwin", "Unicoi"]
    },
    {
      name: "Union",
      government: "https://www.unioncountytn.gov/",
      demographics: "https://data.census.gov/profile/Union_County,_Tennessee?g=050XX00US47173",
      cities: ["Luttrell", "Maynardville", "Plainview City"]
    },
    {
      name: "Washington",
      government: "https://www.washingtoncountytn.org/",
      demographics: "https://data.census.gov/profile/Washington_County,_Tennessee?g=050XX00US47179",
      cities: ["Embreeville", "Fall Branch", "Gray", "Johnson City", "Jonesborough", "Oak Grove", "Spurgeon", "Telford", "Watauga"]
    }
  ];
  
  export default EastTNCounties;
  