import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';  // Import NavLink
import './Header.css';
import HamburgerMenu from './HamburgerMenu';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <header className="header">
      <nav className="nav">
        <div className="logo">
          <NavLink exact to="/">Relocate-TN.com</NavLink> {/* Use NavLink for navigation */}
        </div>
        <ul className="desktop-menu">
          <li><NavLink to="/counties" activeClassName="active-link">TN Counties</NavLink></li>
          <li><NavLink to="/cities" activeClassName="active-link">TN Cities</NavLink></li>
          <li><NavLink to="/government" activeClassName="active-link">State Government</NavLink></li>
          <li><NavLink to="/feedback" activeClassName="active-link">Feedback</NavLink></li>
        </ul>
        <div className="mobile-menu">
          <HamburgerMenu 
            isOpen={menuOpen} 
            onStateChange={handleStateChange} 
            closeMenu={closeMenu} 
          />
        </div>
      </nav>
    </header>
  );
};

export default Header;
