import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from './components/Header';
import Hero from './components/Hero';
import Counties from './components/Counties';
import Cities from './components/Cities'; 
import Feedback from './components/Feedback';
import StateGov from './components/StateGov';
import RealtorRequest from './components/RealtorRequest';
import BuilderRequest from './components/BuilderRequest';
import ServiceProviderApp from './components/ServiceProviderApp';
import Blog from './components/Blog'; 
import WhyTennessee from './blog/posts/WhyTennessee'; 
import Knoxville from './blog/posts/Knoxville';
import RelocateTennessee2024 from './blog/posts/RelocateTennessee2024';
import LookingForVacantLand from './blog/posts/LookingForVacantLand'; 
import TennesseeTaxes from './blog/posts/TennesseeTaxes'; 
import TennesseeClimate from './blog/posts/TennesseeClimate'; 
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop'; // Import ScrollToTop component
import './App.css';

function App() {
  return (
    <Router>
      <ScrollToTop /> {/* ScrollToTop component added */}
      <div className="App">
        <Helmet>
          <title>Relocate-TN</title>
          <meta name="description" content="Relocate to East Tennessee with the best resources and information." />
          <meta name="keywords" content="Relocate, East Tennessee, Real Estate, Builders, Services" />
        </Helmet>
        
        <Header />

        <Routes>
          <Route exact path="/" element={<Hero />} />
          <Route path="/counties" element={<Counties />} />
          <Route path="/cities" element={<Cities />} /> 
          <Route path="/government" element={<StateGov />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/realtor" element={<RealtorRequest />} />
          <Route path="/builder" element={<BuilderRequest />} />
          <Route path="/application" element={<ServiceProviderApp />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/posts/WhyTennessee" element={<WhyTennessee />} />
          <Route path="/blog/posts/Knoxville" element={<Knoxville />} />
          <Route path="/blog/posts/RelocateTennessee2024" element={<RelocateTennessee2024 />} />
          <Route path="/blog/posts/LookingForVacantLand" element={<LookingForVacantLand />} />
          <Route path="/blog/posts/TennesseeTaxes" element={<TennesseeTaxes />} />
          <Route path="/blog/posts/TennesseeClimate" element={<TennesseeClimate />} />
        </Routes>
        
        <Footer />
      </div>
    </Router>
  );
}

export default App;
